import { useSelector } from 'react-redux';
import fastCompare from 'react-fast-compare';

export function useWidgetLayout(widgetId) {
  const dashboardId = useSelector(
    (state) => state.dashboards.selected.relation_id
  );
  const layout = useSelector(
    (state) =>
      state.dashboards.dashboards[dashboardId]?.layout.find(
        (l) => l.i === widgetId
      ),
    fastCompare
  );
  return layout;
}

export default useWidgetLayout;
