const details = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const appSettings = {
  splashPage: false,
  orgRedirect: null,
};

export const admin = {
  users: [],
  ...details,
};

export const auth = {
  user: null,
  authenticated: false,
  currentSession: null,
  ...details,
};

export const cmms = {
  options: {},
  info: [],
  ...details,
};

export const edge = {
  options: {},
  info: [],
  ...details,
};

export const progress = {
  id: null,
  progress: 0,
  expectedTime: 10000,
  visible: false,
  referredFrom: null,
  ...details,
};

export const user = {
  user: null,
  orgUserRelation: null,
  allRelations: [],
  ...details,
};

export const organization = {
  default: {},
  all: [],
  users: [],
  equipment: [],
  invites: [],
  templates: [],
  alarms: [],
  work_orders: [],
  trends: [],
  ...details,
};

export const sites = {
  selected: {},
  sites: [],
  // service_sites: [],
  ...details,
};

export const schedules = {
  schedules: [],
  selected: {},
  exceptionEvents: [],
  scheduleResources: {},
  scheduleDevices: {},
  ...details,
};

export const credentials = {
  credentials: [],
  ...details,
};

export const apps = {
  org_apps: [],
  site_apps: [],
  ...details,
};

export const uploads = {
  files: [],
  folders: [],
  progress: {},
  ...details,
};

export const adminStats = {
  adminStats: {},
  ...details,
};

export const orgStats = {
  eventsStats: null, // array
  ...details,
};

export const devices = {
  devices: [],
  ...details,
};

export const resources = {
  resources: [],
  ...details,
};

export const serviceSites = {
  serviceSites: [],
  ...details,
};

export const trends = {
  trends: [],
  data: [],
  ...details,
};

export const trendCharts = {
  trendCharts: [],
  ...details,
};

export const dashboards = {
  dashboards: {
    // [relationId]: {...},
  },
  data: {
    /* [relationId]: {
        [widgetId]: [],
    }, */
  },
  // TODO -> widgets: {}
  selected: null,
  ...details,
};

export const bulkEdit = {
  selected: [],
  itemType: null,
  onDelete: null,
  onDuplicate: null,
  onEdit: null,
  onDownload: null,
  ...details,
};
