import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { get, sortBy } from 'lodash';

import { addToastr, types } from '../../store/toastr';
import {
  postDashboardWidgetAPI,
  putDashboardWidgetAPI,
  deleteDashboardWidgetAPI,
} from '../../api';
import { getDashboardWidgetData } from './_data';
import { findFirstAvailablePosition } from '../../helpers/dashboards';
import {
  setDashboardData,
  setDashboardWidgetData,
  setSelectedDashboard,
} from '.';

export const postDashboardWidget = createAsyncThunk(
  'dashboards/postDashboardWidget',
  async (
    { dashboardId, data, duplicateId = '' },
    { dispatch, getState, requestId }
  ) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      const initialLayout = initialDashboards[dashboardId].layout;

      const duplicateLayout = duplicateId
        ? initialLayout.find((l) => l.i === duplicateId)
        : {};

      const newWidgetWidth = duplicateLayout.w ?? 12;
      const newWidgetHeight = duplicateLayout.h ?? 6;
      const numCols = 24;

      const { x, y } = findFirstAvailablePosition(
        initialLayout,
        newWidgetWidth,
        newWidgetHeight,
        numCols
      );

      const widgetLayout = {
        x: x,
        y: y,
        w: newWidgetWidth,
        h: newWidgetHeight,
      };

      const { dashboard, widget } = await postDashboardWidgetAPI(
        organization.item_id,
        dashboardId,
        data,
        widgetLayout
      );
      dispatch(
        addToastr({
          title: 'Widget added!',
          type: types.success,
          // message: `Added ${widget.name} to ${dashboard.name}.`,
        })
      );

      dispatch(setSelectedDashboard(dashboard));

      if (duplicateId) {
        const copyWidgetData =
          getState().dashboards.data[dashboardId][duplicateId];
        dispatch(
          setDashboardWidgetData({
            dashboardId,
            widgetId: widget.relation_id,
            data: copyWidgetData,
          })
        );
      } else {
        dispatch(getDashboardWidgetData({ dashboardId, widget }));
      }

      return {
        dashboards: {
          ...initialDashboards,
          [dashboard.relation_id]: dashboard,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to add widget',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const putDashboardWidget = createAsyncThunk(
  'dashboards/putDashboardWidget',
  async ({ dashboardId, data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const organization = getState().organization.default;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await putDashboardWidgetAPI(
        organization.item_id,
        dashboardId,
        data
      );
      dispatch(
        addToastr({
          title: 'Widget updated!',
          type: types.success,
          // message: `Updated ${data.name} in ${updated.name}.`,
        })
      );
      dispatch(getDashboardWidgetData({ dashboardId, widget: data }));

      return {
        dashboards: {
          ...initialDashboards,
          [updated.relation_id]: updated,
        },
      };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to update widget',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  'dashboards/deleteDashboardWidget',
  async ({ dashboardId, data }, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        dashboards: initialDashboards,
      } = getState().dashboards;
      const initialDashboardData =
        getState().dashboards.data[dashboardId] ?? [];

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      const updated = await deleteDashboardWidgetAPI(
        data.item_id,
        dashboardId,
        data.relation_id
      );
      dispatch(
        addToastr({
          title: 'Widget deleted!',
          type: types.success,
          // message: `Removed ${data.name} from ${updated.name}.`,
        })
      );

      const dashboards = {
        ...initialDashboards,
        [updated.relation_id]: updated,
      };

      dispatch(setSelectedDashboard(updated));
      const { [data.relation_id]: _, ...dashboardData } = initialDashboardData;
      dispatch(setDashboardData(dashboardId, dashboardData));
      return { dashboards };
    } catch (err) {
      dispatch(
        addToastr({
          title: 'Failed to delete widget',
          type: types.error,
          message: get(err, 'response.data.reason', 'Bad Request'),
        })
      );
      console.error(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);
