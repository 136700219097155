import { createSlice } from '@reduxjs/toolkit';
import { buildAsyncReducers } from '../thunkTemplate';
import { dashboards as initialState } from '../initialState';

import { pick } from 'lodash';

import {
  getOrganizationDashboards,
  postOrganizationDashboard,
  renameOrganizationDashboard,
  toggleOrganizationDashboardEnabled,
  putOrganizationDashboardLayout,
  deleteOrganizationDashboard,
} from './_dashboards';
import {
  postDashboardWidget,
  putDashboardWidget,
  deleteDashboardWidget,
} from './_widgets';
import { getOrganizationDashboardData, getDashboardWidgetData } from './_data';

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'dashboards',
  initialState,
  reducers: {
    clearDashboards: () => ({ ...initialState }),
    setSelectedDashboard: (state, { payload }) => {
      return {
        ...state,
        selected: payload
          ? pick(payload, [
              'item_id',
              'relation_id',
              'name',
              'site',
              'layout',
              'enabled',
            ])
          : null,
      };
    },
    updateDashboard: (state, { payload }) => {
      const initialDashboard = state.dashboards[payload.relation_id];
      const update = { ...initialDashboard, ...payload };
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [payload.relation_id]: update,
        },
      };
    },
    removeDashboard: (state, { payload }) => {
      const { [payload.relation_id]: _, ...update } = state.dashboards;
      return {
        ...state,
        dashboards: update,
      };
    },
    setDashboardData: (state, { payload }) => {
      const { dashboardId, data } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [dashboardId]: data,
        },
      };
    },
    setDashboardWidgetData: (state, { payload }) => {
      const { dashboardId, widgetId, data } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [dashboardId]: {
            ...state.data[dashboardId],
            [widgetId]: data,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getOrganizationDashboards,
      postOrganizationDashboard,
      renameOrganizationDashboard,
      toggleOrganizationDashboardEnabled,
      putOrganizationDashboardLayout,
      deleteOrganizationDashboard,
      postDashboardWidget,
      putDashboardWidget,
      deleteDashboardWidget,
      getOrganizationDashboardData,
      getDashboardWidgetData,
    ]);
  },
});

// Export the reducer, either as a default or named export
const {
  clearDashboards,
  setSelectedDashboard,
  updateDashboard,
  removeDashboard,
  setDashboardData,
  setDashboardWidgetData,
} = actions;
export {
  clearDashboards,
  setSelectedDashboard,
  updateDashboard,
  removeDashboard,
  setDashboardData,
  setDashboardWidgetData,
  getOrganizationDashboards,
  postOrganizationDashboard,
  renameOrganizationDashboard,
  toggleOrganizationDashboardEnabled,
  putOrganizationDashboardLayout,
  deleteOrganizationDashboard,
  postDashboardWidget,
  putDashboardWidget,
  deleteDashboardWidget,
  getOrganizationDashboardData,
  getDashboardWidgetData,
};
export default reducer;
