import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Fade, Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearBulkEdit } from '../store/bulkEdit';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '1.5rem',
    transform: 'none',
    pointerEvents: 'none',
    zIndex: 6,
  },
  paper: {
    height: '4rem',
    width: '32rem',
    backgroundColor: theme.colors.darkGrey,
    color: theme.palette.getContrastText(theme.colors.darkGrey),
    pointerEvents: 'auto',
  },
  buttonContainer: {
    width: '14%',
    height: '100%',
    padding: '4px 0',
  },
  button: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'capitalize',
    color: theme.palette.getContrastText(theme.colors.darkGrey),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .25)',
    },
  },
  actionButtonIcon: {
    paddingTop: '5px',
  },
  itemCounterContainer: {
    height: '100%',
    minWidth: '4.2rem',
  },
  itemCounter: {
    borderRadius: '4px 0 0 4px',
    backgroundColor: 'red',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.blue,
    color: theme.palette.getContrastText(theme.colors.blue),
    padding: '0 6px',
  },
  labelText: {
    fontSize: '1.2rem',
    textAlign: 'left',
    paddingLeft: '1rem',
  },
}));

const ItemCounter = ({ count }) => {
  const classes = useStyles();
  return (
    <div className={classes.itemCounter}>
      <Typography style={{ fontSize: '40px' }}>{count}</Typography>
    </div>
  );
};

const LabelText = ({}) => {
  const classes = useStyles();
  return <Typography className={classes.labelText}>Items Selected</Typography>;
};

const ActionButton = ({ icon, label, onClick }) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} onClick={onClick}>
      <div>
        <FontAwesomeIcon
          size='2x'
          icon={icon}
          className={classes.actionButtonIcon}
        />
        <Typography variant='subtitle2'>{label}</Typography>
      </div>
    </Button>
  );
};

const CloseButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} onClick={onClick}>
      <FontAwesomeIcon size='2x' icon={['fal', 'xmark']} />
    </Button>
  );
};

export function BulkEditMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selected = useSelector((state) => state.bulkEdit.selected);
  const onDelete = useSelector((state) => state.bulkEdit.onDelete);
  const onDuplicate = useSelector((state) => state.bulkEdit.onDuplicate);
  const onEdit = useSelector((state) => state.bulkEdit.onEdit);
  const onDownload = useSelector((state) => state.bulkEdit.onDownload);

  const [confirmDialog, setConfirmDialog] = useState(null);

  const handleDuplicate = async () => {
    setConfirmDialog({
      title: 'Bulk Duplication',
      content: `Are you sure you want to duplicate ${selected.length} ${
        selected.length > 1 ? 'items' : 'item'
      }?`,
      onConfirm: onDuplicate,
    });
  };

  const handleDelete = async () => {
    setConfirmDialog({
      title: 'Bulk Deletion',
      content: `Are you sure you want to delete ${selected.length} ${
        selected.length > 1 ? 'items' : 'item'
      }? This action cannot be undone.`,
      onConfirm: onDelete,
    });
  };

  const handleEdit = async () => {
    onEdit();
  };

  const handleDownload = async () => {
    onDownload();
  };

  const handleCloseConfirmDialog = async () => {
    handleClose();
    setConfirmDialog(null);
  };

  const handleConfirm = async () => {
    confirmDialog.onConfirm();
    handleCloseConfirmDialog();
  };

  const handleClose = async () => {
    dispatch(clearBulkEdit());
  };

  if (!selected?.length) return null;
  return (
    <div className={classes.root}>
      <Fade in={!!selected?.length}>
        <Paper elevation={6} className={classes.paper}>
          <Grid
            container
            direction='row'
            alignItems='center'
            style={{ height: '100%', margin: 0 }}
          >
            <Grid item className={classes.itemCounterContainer}>
              <ItemCounter count={selected?.length ?? 0} />
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <LabelText />
            </Grid>
            {onDownload && (
              <Grid item className={classes.buttonContainer}>
                <ActionButton
                  label='Download'
                  icon={['fal', 'arrow-down-to-line']}
                  onClick={handleDownload}
                />
              </Grid>
            )}
            {onEdit && (
              <Grid item className={classes.buttonContainer}>
                <ActionButton
                  label='Edit'
                  icon={['fal', 'pen']}
                  onClick={handleEdit}
                />
              </Grid>
            )}
            {onDuplicate && (
              <Grid item className={classes.buttonContainer}>
                <ActionButton
                  label='Duplicate'
                  icon={['fal', 'copy']}
                  onClick={handleDuplicate}
                />
              </Grid>
            )}
            {onDelete && (
              <Grid item className={classes.buttonContainer}>
                <ActionButton
                  label='Delete'
                  icon={['fal', 'trash-can']}
                  onClick={handleDelete}
                />
              </Grid>
            )}
            <Grid item className={classes.buttonContainer}>
              <CloseButton onClick={handleClose} />
            </Grid>
          </Grid>
        </Paper>
      </Fade>
      <ConfirmDialog
        open={!!confirmDialog}
        onClose={handleCloseConfirmDialog}
        title={confirmDialog?.title}
        content={confirmDialog?.content}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default BulkEditMenu;
